<template>
  <div class="wrapper">
    <div class="card">
      <el-page-header
        @back="goBack"
        :content="edit_id ? '编辑截金单' : '新增截金单'"
      >
      </el-page-header>
    </div>
    <div
      class="card"
      style="margin-top: 20px; display: flex; align-items: center"
    >
      <el-button type="primary" icon="el-icon-plus" @click="handleAddHang"
        >新增行</el-button
      >
      <el-button type="primary" @click="goodVisibel = true">筛选货品</el-button>
      <!-- <el-button type="primary" @click="handleDialogVisible"
      v-if="showChangePrice"
        >批量改价</el-button
      >
      <el-button type="primary" @click="handleInfoVisibel"
        >批量改信息</el-button
      > -->
      <el-button type="primary" @click="copyVisible = true">粘贴货号</el-button>
      <!-- <div style="margin-left: 20px;">注意：只有条码类型为<span style="color: #F56C6C;"> 一码多货 </span>才可以修改调拨数量，点击修改，按下Enter键完成。</div> -->
    </div>

    <div
      class="table"
      v-if="rowList.length"
      style="height: 465px; background: white"
      v-loading="loading"
    >
      <u-table
        :data-changes-scroll-top="false"
        ref="plTable"
        :max-height="465"
        use-virtual
        :data="allot_goods"
        showBodyOverflow="title"
        showHeaderOverflow="title"
        :row-key="(row) => row.goods_id"
        @cell-click="hanldeCellClick"
        @selection-change="handleSelectionChange"
        :cell-class-name="getCellIndex"
      >
        <u-table-column
          type="selection"
          width="55"
          align="center"
          :selectable="handleSelectable"
        ></u-table-column>
        <template v-for="(item, index) in rowList">
          <u-table-column
            v-if="item.hide ? false : true"
            align="center"
            :key="index"
            :prop="'' + item.attr_fields"
            :label="item.attr_name"
            :width="
              item.attr_fields === 'goods_number'
                ? 300
                : item.attr_fields === 'goods_type_id'
                ? 300
                : 150
            "
          >
            <template slot="header">
              <span v-if="item.is_mandatory === 20" style="color: orangered"
                >*</span
              >
              {{ item.attr_name }}
            </template>
            <template slot-scope="scope">
              
              <!-- 货号 -->
              <el-input
                :disabled="item.cut_edit? false : true"
                v-if="item.type === 10 && item.attr_fields === 'goods_number'"
                @blur="
                    handleBulr(scope.row, item.attr_fields, scope.row.index)
                  "
                  clearable
                  :id="'input' + scope.row.index + '-' + scope.column.index"
                  v-model="scope.row[item.attr_fields]"
                  placeholder="请输入条码号，按下Enter键结束"
                  @keyup.enter.native="
                    handleKeydown(
                      $event,
                      scope.row[item.attr_fields],
                      item.attr_fields,
                      scope.row.index,
                      scope.column.index,
                      scope.row.num,
                      scope.row
                    )
                  " />

              <!-- !scope.row.goods_id || -->
              <el-input
                :disabled="item.cut_edit? false : true"
                v-if="item.type === 10 && item.attr_fields !== 'goods_number'"
                @blur="
                    handleBulr(scope.row, item.attr_fields, scope.row.index)
                  "
                  clearable
                  :id="'input' + scope.row.index + '-' +scope.column.index"
                  v-model="scope.row[item.attr_fields]"
                  placeholder="请输入" />
                  

                <el-select
                :disabled="item.cut_edit? false : true"
                  v-if="item.type === 20 && item.attr_fields !== 'goods_type_id'"
                  v-model="scope.row[item.attr_fields]"
                >
                  <template v-for="val in item.val_list">
                    <el-option
                      :key="val.attr_value_id"
                      :label="val.attr_value"
                      :value="val.attr_value"
                    ></el-option>
                  </template>
                </el-select>

                <!-- 数值 -->
                <el-input
                v-if="item.type === 30"
                type="number"
                :disabled="item.cut_edit? false : true"
                @blur="
                    handleBulr(scope.row, item.attr_fields, scope.row.index)
                  "
                  @input="handleInput(scope.row,item.cut_edit)"
                  clearable
                  :id="'input' + scope.row.index + '-' +scope.column.index"
                  v-model="scope.row[item.attr_fields]"
                  placeholder="请输入" />

                  <!-- 自动计算 -->
                  <el-input v-if="item.type === 40" v-model="scope.row[item.attr_fields]" disabled placeholder="自动计算" />

                  <span v-if="item.type === 50">{{scope.row[item.attr_fields]}}</span>
                
                <SelectTree
                  :disabled="item.cut_edit? false : true"
                  v-if="item.type === 20 && item.attr_fields === 'goods_type_id' && show"
                  :value="goods_type_id"
                  :props="props"
                  :options="item.val_list"
                  :clearable="isClearable"
                  :accordion="isAccordion"
                  @getValue="getValue($event)"
                />
            </template>
            <!-- <template slot-scope="scope">
              <template
                v-if="
                  (item.attr_fields !== 'goods_number' && scope.row.goods_id) ||
                  (item.attr_fields === 'goods_number' && !scope.row.goods_id)
                "
              >
                <el-input
                  @blur="
                    handleBulr(scope.row, item.attr_fields, scope.row.index)
                  "
                  clearable
                  :id="'input' + scope.row.index + scope.column.index"
                  v-model="scope.row[item.attr_fields]"
                  :placeholder="
                    item.attr_fields === 'goods_number' ? '请输入条码号' : ''
                  "
                  v-show="
                    item.type === 10 &&
                    coordinate.column === scope.column.index &&
                    coordinate.row === scope.row.index
                  "
                  @keyup.enter.native="
                    handleKeydown(
                      $event,
                      scope.row[item.attr_fields],
                      item.attr_fields,
                      scope.row.index,
                      scope.column.index,
                      scope.row.num,
                      scope.row
                    )
                  "
                >
                </el-input>
                <span
                  v-show="
                    item.type === 10 &&
                    (scope.row.index !== coordinate.row ||
                      scope.column.index !== coordinate.column)
                  "
                >
                  {{ scope.row[item.attr_fields] }}
                  <span
                    v-if="!scope.row[item.attr_fields]"
                    style="color: #ccc"
                    >{{
                      item.attr_fields === "goods_number"
                        ? "请点击输入条码号，按下Enter键结束"
                        : ""
                    }}</span
                  >
                </span>
                <template v-if="item.attr_fields === 'goods_name'">
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img
                      :src="item"
                      :key="item"
                      style="margin-left: 5px; height: 15px"
                    />
                  </template>
                </template>
                <el-select
                  v-if="
                    item.type === 20 && item.attr_fields !== 'goods_type_id'
                  "
                  v-model="scope.row[item.attr_fields]"
                >
                  <template v-for="val in item.val_list">
                    <el-option
                      :key="val.attr_value_id"
                      :label="val.attr_value"
                      :value="val.attr_value"
                    ></el-option>
                  </template>
                </el-select>
                <SelectTree
                  v-if="
                    item.type === 20 &&
                    item.attr_fields === 'goods_type_id' &&
                    show
                  "
                  :value="goods_type_id"
                  :props="props"
                  :options="item.val_list"
                  :clearable="isClearable"
                  :accordion="isAccordion"
                  @getValue="getValue($event)"
                />
              </template>
              <template v-else>
                {{
                  item.prop === "goods_number_type"
                    ? scope.row[item.attr_fields] === 10
                      ? "一码一货"
                      : scope.row[item.attr_fields] === 20
                      ? "一码多货"
                      : ""
                    : scope.row[item.attr_fields]
                }}
              </template>
            </template> -->
          </u-table-column>
        </template>
        <u-table-column
          align="center"
          fixed="right"
          prop="operation"
          label="操作"
        >
          <template slot-scope="scope">
            <div class="options">
              <el-button type="text" @click="handleDele(scope.row.index, scope.row)"
                :disabled="scope.row.can_doing === 0 ? true : false"
                >删除</el-button
              >
            </div>
          </template>
        </u-table-column>
      </u-table>
    </div>

    <div class="table total" style="border-top: 0px">
      <div class="total-left">合计</div>
      <div class="total-right">
        <div class="total-right-item">
          修改数量：<span>{{ total || "0" }}</span>
        </div>
        <div class="total-right-item">
          金重(g)：<span>{{ weight || "0.00" }}</span>
        </div>
        <div class="total-right-item">
          标签价：<span>{{ sale_price || "0.0000" }}</span>
        </div>
      </div>
    </div>
    <div class="remark">
      <div style="width: 50px">备注</div>
      <el-input
        type="textarea"
        :autosize="{ minRows: 3, maxRows: 3 }"
        placeholder="请输入内容"
        v-model="remark"
      >
      </el-input>
    </div>
    <el-form :model="formTime" inline   style="margin-bottom: 80px;">
        <el-form-item label="创建时间" v-if="edit_id && row.edit_status === 20">
          <el-date-picker
            v-model="formTime.create_time"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="审核时间" v-if="edit_id && row.edit_status === 20">
          <el-date-picker
            v-model="formTime.audit_time"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
    <!-- <Add :allot_goods="this.allot_goods" /> -->
    <!-- <div class="form" style="margin-bottom: 80px;">
      <el-form :model="form" label-width="80px">
        <el-form-item label="调入门店" required>
          <el-select v-model="form.to_hierarchy_id" clearable>
            <template v-for="item in merchantList">
              <el-option :label="item.hierarchy_name" :value="item.hierarchy_id" :key="item.hierarchy_id"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="form.allot_remark"
            type="textarea"
            :autosize="{ minRows: 8 }"
          ></el-input>
        </el-form-item>
      </el-form>
    </div> -->
    <div class="btns">
      <el-button
        type="primary"
        style="margin-right: 20px"
        @click="handleSubmit"
        :loading="loading"
        v-if="row.edit_status === 20"
        >保存并锁定</el-button
      >
      <el-button
       v-else
        type="primary"
        style="margin-right: 20px"
        @click="handleSubmit"
        :loading="loading"
        >提交审核</el-button
      >
      <el-button type="primary" plain @click="handleCancel">取消</el-button>
    </div>
    <el-dialog
      title="批量改价"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form :model="form" label-width="80px">
        <el-form-item label="价格类型">
          <el-select v-model="form.type" clearable>
            <template v-for="item in changePriceArr">
              <el-option :key="item.attr_fields" :label="item.attr_name" :value="item.attr_fields" v-if="!item.hide" :disabled="item.disabled"></el-option>
            </template>
            <!-- <el-option label="标签价" value="sale_price"></el-option>
            <el-option label="成本价" value="cost_price"></el-option> -->
            <!-- <el-option label="一口价" :value="3"></el-option> -->
          </el-select>
          <el-tooltip placement="right">
            <div slot="content">
              价格类型：按照价格类型进行改价。
              <br />
              <br />标签价：表示在标签价的基础上，按照价格比例，重新计算标签价。
              <br />
              <br />成本价：表示在成本价的基础上，按照价格比例，重新计算标签价。
              <br />
              <!-- <br />一口价：将标签价统一改为一口价。 -->
            </div>
            <i
              class="el-icon-info"
              style="color: #ffa21a; margin-left: 10px"
            ></i>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="价格比例">
          <el-input v-model="form.bili" clearable>
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleChangePrice">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="批量改信息"
      :visible.sync="infoVisibel"
      width="30%"
      :before-close="handleClose"
    >
      <div class="tip">
        <i class="el-icon-info" style="color: #ffa21a; margin-right: 5px"></i
        >请选择要修改的列，将这一列信息统一修改。
      </div>
      <el-form :model="formInfo" label-width="120px">
        <el-form-item label="选择修改列">
          <el-select
            v-model="formInfo.column"
            placeholder="请选择"
            @change="handleSelect"
            filterable
          >
            <template v-for="item in rowList">
              <el-option
                :label="item.attr_name"
                :value="item.attr_fields"
                :key="item.attr_fields"
                :disabled="item.attr_fields === 'goods_number' || item.attr_fields === 'goods_type_id' || item.type === 40 || item.disabled"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="修改的值">
          <el-input
            v-model="formInfo.value"
            v-if="!currSelect.type || currSelect.type === 10"
          ></el-input>
          <el-select v-if="currSelect.type === 20" v-model="formInfo.value">
            <template v-for="item in currSelect.val_list">
              <el-option
                :lable="item.attr_value_id"
                :value="item.attr_value"
                :key="item.attr_value_id"
              ></el-option>
            </template>
          </el-select>
          <el-input
            v-model="formInfo.value"
            v-if="currSelect.type === 30"
            type="number"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="infoVisibel = false">取 消</el-button>
        <el-button type="primary" @click="handleChangeInfo">确 定</el-button>
      </span>
    </el-dialog>
   
    <el-dialog title="粘贴货号" :visible.sync="copyVisible" width="30%" :before-close="handleCopyHide">
      <el-input
        v-if="!copyErrVisible"
        type="textarea"
        v-model="copyString"
        :autosize="{ minRows: 8, maxRows: 8 }"
      ></el-input>
      <div v-else>
        <div v-for="(item, index) in noCopyArr" :key="index">
          {{index + 1}}.{{item.txt}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer"  v-if="!copyErrVisible">
        <el-button @click="handleCopyHide">取 消</el-button>
        <el-button type="primary" @click="handleCopy" :loading="copyLoading"
          >确 定</el-button
        >
      </span>
      <span slot="footer" class="dialog-footer" v-else>
        <el-button @click="handleCopyHide">取 消</el-button>
        <el-button type="primary" @click="handleCopyContinue"
          >粘贴可操作货号</el-button
        >
      </span>
    </el-dialog>

    <choose-goods
      :goodVisibel="goodVisibel"
      @close="goodVisibel = false"
      @getGoods="handleGoods"
      :formItemList="formItemList"
      :selectGoods="allot_goods"
      search_value_type="itemValue"
      :goods_number_type="10"
      :field_is_show="1"
      v-if="goodVisibel"
    />

  </div>
</template>

<script>
import storage from "good-storage";
import __tag__ from "@/utils/tag.js";
import chooseGoods from "./components/chooseGoods.vue";
// import { createReq } from "@/api/goods/goodsWarehousing/modifyList/add";
import { detailGoodsListReq, resetDetailGoodsListReq, resetReq } from "@/api/goods/goodsWarehousing/modifyList/detail";
import goodsReq from "@/components/goods/chooseGoods/index";
import numeral from "numeral";
import {
  getGoodsCalcuReq
} from "@/api/goods/goodsWarehousing/warehousing";
import {getAttrList,createOrder,detailGoodsList,resetDetailGoodsList,resetSave} from '@/api/order/offlineOrder/cut'
export default {
  data() {
    this.tag = __tag__;
    return {
      formTime: {
        create_time: '',
        audit_time: '',
      },
      showChangePrice: false,
      changePriceArr: [], // 改价数组
      canCopyArr: [], // 可粘贴的货号
      noCopyArr: [],
      copyErrVisible: false,
      copyLoading: false,
      copyString: "",
      copyVisible: false,
      noWatch: false,
      timer: null,
      show: true,
      isClearable: false, // 可清空（可选）
      isAccordion: true, // 可收起（可选）
      props: {
        value: "goods_type_id",
        label: "goods_type_name",
        children: "children",
      },
      treeData: [],
      remark: "", // 新增修改单时的备注
      multipleSelection: [],
      currSelect: {},
      formInfo: {}, // 批量改信息的表单
      loading: false,
      total: "",
      weight: "",
      sale_price: "",
      merchantList: [],
      formItemList: [
        {
          label: "品类",
          placeholder: "请填写品类",
          attr: "goods_type_id",
          type: "text",
          value: this.goods_type_id,
          text: this.text,
        },
        {
          label: "货号",
          placeholder: "请填写货号",
          attr: "goods_number",
          type: "input",
        },
        {
          label: "入库单号",
          placeholder: "请填写入库单号",
          attr: "entry_number",
          type: "input",
        },
        {
          label: "盘点单号",
          placeholder: "请填写盘点单号",
          attr: "check_number",
          type: "input",
        },
        {
          label: "调拨单号",
          placeholder: "请填写调拨单号",
          attr: "allot_number",
          type: "input",
        },
        {
          label: "调仓单号",
          placeholder: "请填写调仓单号",
          attr: "allot_warehouse_number",
          type: "input",
        },
        {
          label: "修改单号",
          placeholder: "请填写修改单号",
          attr: "edit_number",
          type: "input",
        },
        {
          label: "款号",
          placeholder: "请填写入款号",
          attr: "goods_style_number",
          type: "input",
        },
        {
          label: "品名",
          placeholder: "请填写入品名",
          attr: "goods_name",
          type: "input",
        },
        { type: "hierarchy_id", label: "所属门店" },
        { type: "warehouse_id", label: "仓库" },
        { type: "101", label: "款式" },
        { type: "100", label: "材质成色" },
        { type: "103", label: "品牌" },
        { type: "sale_price", label: "标签价" },
        { type: "104", label: "净金重" },
        { type: "entry_time", label: "入库时间" },
        // {
        //   label: "调拨单号",
        //   placeholder: "请填写调拨单号",
        //   attr: "allot_id",
        //   type: "input",
        // },
      ],
      userInfo: {},
      formInline: {},
      form: {},
      arr: [{}],
      goodVisibel: false,
      infoVisibel: false,
      tableVisiable: false,
      dialogVisible: false,
      rowList: [],
      coordinate: { column: -1, row: -1 },
      allot_goods: [],
    };
  },
  props: {
    goods_type_id: {
      type: Number,
    },
    edit_id: Number,
    text: String,
    row: Object,
  },
  components: {
    chooseGoods,
    // Add,
  },

  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.allot_goods = [...this.arr];
    console.log(this.goods_type_id)
    this.handleArrList(this.goods_type_id);
    if (this.edit_id) {
      // 普通编辑
      if (this.row.edit_status !== 20) {
        this.getDetailGoods();
      } 
      else {
        // 反确认编辑
        this.resetDetailGoodsList();
      }
    }
  },

  watch: {
    allot_goods: {
      handler(val) {
        if (this.noWatch) {
          return;
        }
        val.forEach((row, index) => {
          this.rowList.forEach((item) => {
            if (item.type === 40) {
              let m = 0;
              const cacluArray = item.caclu_field_string.split(',');
              cacluArray.forEach((data) => {
                if (row[data] || row[data] === 0 || row[data] === '0') {
                  m += 1;
                }
                if (m === cacluArray.length - 1) {
                  // 公式内的所有值都存在时or等于0时才能计算
                  const result = numeral(eval(item.caclu_font)).format('0.0000');
                  const newRow = { ...row, [item.attr_fields]: result };
                  this.noWatch = true;
                  this.$set(this.allot_goods, index, newRow);
                } else {
                  const result = '';
                  const newRow = { ...row, [item.attr_fields]: result };
                  this.noWatch = true;
                  this.$set(this.allot_goods, index, newRow);
                }
              });
            }
          });
        });
        console.log(this.allot_goods);
      },
      deep: true,
    },
  },
  methods: {
    handleCopyContinue() {
      if (!this.canCopyArr.length) {
        this.$message.warning('没有可粘贴货号');
      }
      this.handleGoods(this.canCopyArr);
      this.copyString = '';
      this.canCopyArr = [];
      this.handleCopyHide();
    },
    handleCopyHide() {
      this.copyVisible = false;
      setTimeout(() => {
        this.copyErrVisible = false;
      }, 500);
    },
    // 处理zhantie
    handleCopy() {
      this.copyLoading = true;
      let arr = this.copyString.split(/[\r\b\t\n\f]/g);
      const canCopyArr = [];
      const noCopyArr = [];
      arr = arr.filter((item) => {
        if (item) {
          return item;
        }
      });
      arr.forEach((item) => {
        if (item) {
          // 去搜索 需要看结果是否符合条件
          goodsReq({
            goods_number: item,
            allot_state: 1,
            // query_type: "check_goods",
          }).then((res) => {
            if (res.data.list.length) {
              // 是否符合条件
              // 必须是同仓库的
              if (res.data.list[0].goods_type_id === this.goods_type_id) {
                if (res.data.list[0].usable_count) {
                  canCopyArr.push(res.data.list[0]);
                } else {
                  if (res.data.list[0].goods_tag_id) {
                    const goods_tag_id = res.data.list[0].goods_tag_id.split(
                      ","
                    );
                    // 不能选择的标志
                    const tagList = [
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "9",
                      "10",
                      "11",
                      "12",
                    ];
                    goods_tag_id.forEach((tag_id) => {
                      tagList.forEach((tag) => {
                        if (tag_id === tag) {
                          noCopyArr.push({
                            txt: `货号 ${item} 正在${this.tag[tag_id]}，不可粘贴`,
                          });
                        }
                      });
                    });
                  }
                }
              } else {
                noCopyArr.push({
                  txt: `货号${item}的品类与当前品类不同，不可粘贴`
                });
              }
            } else {
              noCopyArr.push({
                txt: `货号 ${item} 不存在`,
              });
            }
            if (canCopyArr.length + noCopyArr.length === arr.length) {
              this.copyLoading = false;
              if (!noCopyArr.length) {
                this.handleGoods(canCopyArr);
                this.copyVisible = false;
                this.copyString = '';
              } else {
                // 弹框哪些不能复制
                this.canCopyArr = canCopyArr;
                this.noCopyArr = noCopyArr;
                this.copyString = '';
                this.copyErrVisible = true;
              }
            }
          });
        }
      });
    },
    handleInput(row) {
      this.noWatch = false;
      // 自动计算 此处需要节流
      // if (this.timer) {
      //   clearInterval(this.timer);
      // }
      // this.timer = setTimeout(() => {
      //   console.log(row.index)
      //   const newRow = { ...row, noChange: false };
      //   this.allot_goods.splice(row.index - 1, 1, newRow);
      // }, 500);
      // 自动计算 此处需要节流
  //     if (this.timer) {
  //       clearInterval(this.timer);
  //     }
  //     this.timer = setTimeout(() => {
  //       getGoodsCalcuReq({
  //         goods_number_type: row.goods_number_type,
  //         goods_type_id: row.goods_type_id,
  //         entry_goods_json: JSON.stringify({ ...row }),
  //       })
  //         .then((res) => {
  //           if (res.code === 1) {
  //             const newRow = { ...row, ...res.data };
  //             this.allot_goods.splice(row.index - 1, 1, newRow);

  //           }
  //         })
  //     }, 500);
    },
    getValue(value) {
      this.handleArrList(value);
      this.$emit("onChangeValue", value);
    },
    handleAddHang() {
      this.allot_goods.push({
        index: "",
        goods_number: "",
        goods_name: "",
        101: "",
        102: "",
        103: "",
        104: "",
        sale_price: "",
        num: "",
      });
    },
    handleSelectable(row, index) {
      if (!row.goods_id) {
        return false;
      } else if (row.can_doing === 0) {
        return false;
      } else {
        return true;
      }
    },
    handleBulr(rowData, prop, row) {
      this.coordinate = {
        column: -1,
        row: -1,
      };
      // 成本价 * 倍率 = 标签价
      // 如果填写的是成本价
      const newRowData = { ...rowData };
      if (prop === "cost_price") {
        newRowData.cost_price = numeral(newRowData.cost_price).format("0.00");
        // 如果倍率存在，算出标签价
        // if (newRowData.price_times) {
        //   newRowData.sale_price = numeral(newRowData.cost_price * newRowData.price_times).format('0.00');
        // }
        // if (newRowData.sale_price) {
        //   newRowData.price_times = numeral(newRowData.sale_price / newRowData.cost_price).format('0.00');
        // }
        newRowData.price_times = "";
      }

      // 如果填写的是倍率
      if (prop === "price_times") {
        if (newRowData.price_times) {
          newRowData.price_times = numeral(newRowData.price_times).format(
            "0.00"
          );
          // 如果成本价存在，算出标签价
          if (newRowData.cost_price) {
            newRowData.sale_price = numeral(
              newRowData.cost_price * newRowData.price_times
            ).format("0.00");
          }
        }
        // if (newRowData.sale_price) {
        //   newRowData.price_times = numeral(newRowData.sale_price / newRowData.cost_price).format('0.00');
        // }
      }

      // 如果填写的是标签价
      if (prop === "sale_price") {
        newRowData.sale_price = numeral(newRowData.sale_price).format("0.00");
        // 如果成本价存在，算出标签价
        // if (newRowData.cost_price) {
        //   newRowData.price_times = numeral(newRowData.sale_price / newRowData.cost_price).format('0.00');
        // }
        // if (newRowData.price_times) {
        //   newRowData.cost_price = numeral(newRowData.sale_price / newRowData.price_times).format('0.00');
        // }
        newRowData.price_times = "";
      }
      this.allot_goods.splice(row - 1, 1, newRowData);
    },
    // 反确认详情
    resetDetailGoodsList() {
      resetDetailGoodsList({
        cut_metarial_id: this.edit_id,
        page: 1,
        page_num: 99999,
      }).then((res) => {
        if (res.code === 1) {
          const newArr = [];
          res.data.list.forEach((item) => {
            if (item.goods_info) {
              // newArr.push({ ...item.goods_info.old_info, label: '原始', edit_id: item.edit_id, goods_id: item.goods_id, goods_info: item.goods_info, goods_number: item.goods_number });
              this.allot_goods.unshift({
                ...item.goods_info.new_info,
                label: "修改",
                edit_id: item.edit_id,
                goods_id: item.goods_id,
                goods_number: item.goods_number,
                can_doing: item.can_doing,
              });
            }
          });
          this.remark = res.data.remark;
          this.formTime.create_time = res.data.create_time;
          this.formTime.audit_time = res.data.audit_time;
          if (this.edit_id) {
            this.handleSum();
          }
          // this.allot_goods = newArr;
        }
      });
    },
    getDetailGoods() {
      detailGoodsList({
        edit_id: this.edit_id,
        page: 1,
        page_num: 99999,
      }).then((res) => {
        if (res.code === 1) {
          const newArr = [];
          res.data.list.forEach((item) => {
            if (item.goods_info) {
              // newArr.push({ ...item.goods_info.old_info, label: '原始', edit_id: item.edit_id, goods_id: item.goods_id, goods_info: item.goods_info, goods_number: item.goods_number });
              this.allot_goods.unshift({
                ...item.goods_info.new_info,
                label: "修改",
                edit_id: item.edit_id,
                goods_id: item.goods_id,
                goods_number: item.goods_number,
              });
            }
          });
          this.remark = res.data.remark;
          if (this.edit_id) {
            this.handleSum();
          }
          // this.allot_goods = newArr;
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelect(val) {
      const currSelect = this.rowList.filter(
        (item) => item.attr_fields === val
      );
      this.currSelect = currSelect[0];
    },
    handleChangeInfo() {
      let allot_goods = JSON.parse(JSON.stringify(this.allot_goods));
      allot_goods.forEach((item) => {
        this.multipleSelection.forEach((mul) => {
          if (item.goods_id) {
            if (item.goods_id === mul.goods_id) {
              item[this.formInfo.column] = this.formInfo.value;
            }
            // 成本价 * 倍率 = 标签价
            const column = this.formInfo.column;
            const value = this.formInfo.value;
            if (column === "cost_price") {
              item.cost_price = numeral(value).format("0.00");
              // if (item.price_times) {
              //   item.sale_price = numeral(value * item.price_times).format('0.00');
              // }
              // if (item.sale_price) {
              //   item.price_times = numeral(item.sale_price / value).format('0.00');
              // }
              item.price_times = "";
            }
            if (column === "price_times") {
              if (item.price_times) {
                item.price_times = numeral(value).format("0.00");
                if (item.cost_price) {
                  item.sale_price = numeral(item.cost_price * value).format(
                    "0.00"
                  );
                }
              }
              // if (item.sale_price) {
              //   item.cost_price = numeral(item.sale_price / value).format('0.00');
              // }
            }
            if (column === "sale_price") {
              item.sale_price = numeral(value).format("0.00");
              // if (item.cost_price) {
              //   item.price_times = numeral(value / item.cost_price).format('0.00');
              // }
              // if (item.price_times) {
              //   item.cost_price = numeral(value / item.price_times).format('0.00');
              // }
              item.price_times = "";
            }
          }
        });
      });
      this.allot_goods = allot_goods;
      this.handleClose();
    },
    handleChangePrice() {
      let allot_goods = JSON.parse(JSON.stringify(this.allot_goods));
      allot_goods.forEach((item) => {
        this.multipleSelection.forEach((mul) => {
          if (item.goods_id) {
            if (item.goods_id === mul.goods_id) {
              item[this.form.type] = numeral(
                Number(item[this.form.type]) * Number(this.form.bili / 100)
              ).format("0.00");
            }
          }
        });
      });
      this.handleClose();
      this.allot_goods = allot_goods;
      this.dialogVisible = false;
    },
    handleInfoVisibel() {
      const arr = this.allot_goods.filter((item) => item.goods_id);
      if (!arr.length) {
        this.$message.error("请先筛选货品");
        return;
      }
      if (!this.multipleSelection.length) {
        this.$message.error("请先勾选货品");
        return;
      }
      this.infoVisibel = true;
    },
    handleDialogVisible() {
      const arr = this.allot_goods.filter((item) => item.goods_id);
      if (!arr.length) {
        this.$message.error("请先筛选货品");
        return;
      }
      if (!this.multipleSelection.length) {
        this.$message.error("请先勾选货品");
        return;
      }
      this.dialogVisible = true;
    },

    // 获取列表的列
    handleArrList(goods_type_id) {
      this.loading = true;
      getAttrList({ goods_type_id,isCutMetarial:1 })
        .then((res) => {
          if (res.code === 1) {
            console.log(res)
            this.loading = false;
            // 反确认
            if (this.row.edit_status === 20) {
              res.data.push({
                attr_name: '可操作数量',
                type: 50,
                attr_fields: 'can_doing',
              });
            }
            this.rowList = res.data;
            console.log(this.rowList)
            const changePriceArr = [];
            let m = 0;
            res.data.forEach((item) => {
              if (item.attr_fields === 'cost_price') {
                if (item.disabled) {
                  m += 1;
                }
                changePriceArr.push(item);
              }
              if (item.attr_fields === 'sale_price') {
                if (item.disabled) {
                  m += 1;
                }
                changePriceArr.push(item);
              }
            });
            if (m === 2) {
              this.showChangePrice = false;
            } else {
              this.showChangePrice = true;
            }
            this.changePriceArr = changePriceArr;
            if (!this.edit_id) {
              this.coordinate = { row: 1, column: 1 };
              this.$nextTick(() => {
                try {
                  const dom = document.getElementById(
                    "input" + this.coordinate.row + this.coordinate.column
                  );
                  dom.focus();
                } catch (error) {
                  // console.log('无法聚焦');
                }
              });
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    
    // 提交审核
    handleSubmit() {
      // const newArr = [];
      // let obj = {};
      // this.allot_goods.forEach((item) => {
      //   if (item.goods_id) {
      //     obj = {
      //       goods_id: item.goods_id,
      //       num: item.num,
      //     };
      //     newArr.push(obj);
      //   }
      // });
      const newArr = this.allot_goods.filter((item) => item.goods_id);
      if (!newArr.length) {
        this.$message.error("请筛选货品");
        return;
      }
      // 处理传给后端的数据
      const postData = [];
      for (let i = 0; i < newArr.length; i += 1) {
        const item = newArr[i];
        const obj = {
          goods_id: item.goods_id,
        };
        if (item.edit_goods_id) {
          obj.edit_goods_id = item.edit_goods_id;
        }
        for (let t = 0; t < this.rowList.length; t += 1) {
          const row = this.rowList[t];
          obj.num = item.num;
          obj[row.attr_fields] = item[row.attr_fields];
          obj.goods_type_id = this.goods_type_id;
          // 判断必填项
          if (row.is_mandatory === 20) {
            if (!item[row.attr_fields]) {
              this.$message.error(`${row.attr_name}为必填项`);
              return;
            }
          }
        }
        if (this.row.edit_status === 20) {
          obj.can_doing = item.can_doing;
        }
        postData.push(obj);
      }
      // newArr.forEach((item) => {
      //   const obj = {
      //     goods_id: item.goods_id,
      //   };
      //   this.rowList.forEach((row) => {
      //     obj[row.attr_fields] = item[row.attr_fields];
      //     // 判断必填项
      //     if (row.is_mandatory === 20) {
      //       if (!item[row.attr_fields]) {
      //         this.$message.error(`${row.attr_name}为必填项`);
      //       }
      //     }
      //   });
      //   postData.push(obj);
      // });
      this.loading = true;
      console.log(this.row)
      if (this.row.edit_status !== 20) {
        console.log('1111111111111111')
        createOrder({
          edit_goods: postData,
          total_num: this.total || 0,
          goods_type_id: this.goods_type_id,
          remark: this.remark,
          cut_metarial_id: this.edit_id,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.$emit("hide");
              this.$emit("getList");
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        console.log('22222222222222222')
        resetSave({
          edit_goods: postData,
          total_num: this.total || 0,
          goods_type_id: this.goods_type_id,
          remark: this.remark,
          cut_metarial_id: this.edit_id,
          ...this.formTime,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.$emit("hide");
              this.$emit("getList");
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    handleDele(index, info) {
      if (this.row.edit_status === 20 && info.goods_id) {
        this.$confirm('确定要删除吗？删除后将无法恢复?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.allot_goods.splice(index - 1, 1);
            this.handleSum();
          });
      } else {
        this.allot_goods.splice(index - 1, 1);
        this.handleSum();
      }
    },
    handleSum() {
      let total = 0;
      let weight = 0;
      let sale_price = 0;
      this.allot_goods.forEach((item) => {
        if (item.goods_id) {
          total += Number(item.num);
          if (item["104"]) {
            weight += Number(item["104"]) * item.num;
          }
          sale_price += Number(item.sale_price) * item.num;
        }
      });
      this.total = total;
      this.weight = numeral(weight).format("0.00");
      this.sale_price = numeral(sale_price).format("0.00");
    },
    handleCancel() {
      this.$emit("hide");
    },
    // 聚焦
    handleFocus(row, column) {
      const coordinate = { ...this.coordinate };
      coordinate.row = row;
      coordinate.column = column;
      this.coordinate = coordinate;
      this.$nextTick(() => {
        try {
          const dom = document.getElementById(
            "input" + coordinate.row + coordinate.column
          );
          dom.value = "";
          dom.focus();
        } catch (error) {
          // console.log('无法聚焦');
        }
      });
    },
    handleKeydown(val, value, prop, row, column, num, rowData) {
      // if (!value) {
      //   this.$message.error('请填入');
      //   return;
      // }
      if (value > num && prop === "num") {
        this.$message.error("编辑不能超过原有的数量");
        return;
      }
      if (val.keyCode === 13) {
        // if (!/^[0-9]*$/.test(value)) {
        //   this.$message.error('请输入数字');
        // } else {
        // }
        this.coordinate = {
          column: -1,
          row: -1,
        };
        if (prop === "goods_number") {
          if (!value) {
            return;
          }
          // 请求接口
          goodsReq({
            goods_number: value,
            allot_state: 1,
            goods_type_id: this.goods_type_id,
            goods_number_type:10,
          })
            .then((res) => {
              if (res.code === 1) {
                if (!res.data.total) {
                  this.$message.warning("查询不到该货号");
                  this.allot_goods[row - 1].goods_number = "";
                  const coordinate = { ...this.coordinate };
                  coordinate.row = row;
                  coordinate.column = column;
                  this.coordinate = coordinate;
                  this.$nextTick(() => {
                    try {
                      const dom = document.getElementById(
                        "input" + coordinate.row + '-' +coordinate.column
                      );
                      dom.value = "";
                      dom.focus();
                    } catch (error) {
                      // console.log('无法聚焦');
                    }
                  });
                  return;
                }
                let breakFlg = false;
                if (res.data.list[0].goods_tag_id) {
                  const goods_tag_id = res.data.list[0].goods_tag_id.split(",");
                  // 不能选择的标志
                  const tagList = [
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "9",
                    "10",
                    "11",
                    "12",
                  ];
                  goods_tag_id.forEach((item) => {
                    tagList.forEach((tag) => {
                      if (item === tag) {
                        // 防止一码多货，需要判断可操作为0
                        if (!res.data.list[0].usable_count) {
                          this.$message.warning(
                            `该货品状态：${this.tag[item]}，不可操作`
                          );
                          this.handleFocus(row, column);
                          this.allot_goods[row - 1].goods_number = "";
                          breakFlg = true;
                        }
                      }
                    });
                  });
                }
                if (breakFlg) {
                  return;
                }
                if (res.data.list[0].goods_number_type === 20) {
                  this.$message.warning("一码多货的货品不可修改");
                  this.handleFocus(row, column);
                  return;
                }
                // 数据是否有重复
                let m = 0;
                this.allot_goods.forEach((item) => {
                  if (item.goods_id !== res.data.list[0].goods_id) {
                    m += 1;
                    if (m === this.allot_goods.length) {
                      this.allot_goods.splice(row - 1, 1, ...res.data.list);
                      // 新增一条空数据
                      this.allot_goods.push({});
                      this.$refs.plTable.scrollBottom();
                      this.handleSum();
                      const coordinate = { ...this.coordinate };
                      coordinate.row = row + 1;
                      coordinate.column = column;
                      this.coordinate = coordinate;
                      this.$nextTick(() => {
                        try {
                          const dom = document.getElementById(
                            "input" + coordinate.row + '-' + coordinate.column
                          );
                          dom.focus();
                        } catch (error) {
                          // console.log('无法聚焦');
                        }
                      });
                    }
                  } else {
                    this.$message.warning("该数据已存在。");
                    const coordinate = { ...this.coordinate };
                    coordinate.row = row;
                    coordinate.column = column;
                    this.coordinate = coordinate;
                    this.$nextTick(() => {
                      try {
                        const dom = document.getElementById(
                          "input" + coordinate.row + '-' + coordinate.column
                        );
                        dom.value = "";
                        dom.focus();
                      } catch (error) {
                        // console.log('无法聚焦');
                      }
                    });
                  }
                });
              }
            })
            .catch(() => {
              const coordinate = { ...this.coordinate };
              coordinate.row = row;
              coordinate.column = column;
              this.coordinate = coordinate;
              this.$nextTick(() => {
                try {
                  const dom = document.getElementById(
                    "input" + coordinate.row + '-' + coordinate.column
                  );
                  dom.value = "";
                  dom.focus();
                } catch (error) {
                  // console.log('无法聚焦');
                }
              });
            });
        }
        if (prop === "num") {
          this.handleSum();
        }
        // 成本价 * 倍率 = 标签价

        // 如果填写的是成本价
        const newRowData = { ...rowData };
        if (prop === "cost_price") {
          newRowData.cost_price = numeral(newRowData.cost_price).format("0.00");
          // 如果倍率存在，算出标签价
          // if (newRowData.price_times) {
          //   newRowData.sale_price = numeral(newRowData.cost_price * newRowData.price_times).format('0.00');
          // }
          // if (newRowData.sale_price) {
          //   newRowData.price_times = numeral(newRowData.sale_price / newRowData.cost_price).format('0.00');
          // }
          newRowData.price_times = "";
        }

        // 如果填写的是倍率
        if (prop === "price_times") {
          if (newRowData.price_times) {
            newRowData.price_times = numeral(newRowData.price_times).format(
              "0.00"
            );
            // 如果成本价存在，算出标签价
            if (newRowData.cost_price) {
              newRowData.sale_price = numeral(
                newRowData.cost_price * newRowData.price_times
              ).format("0.00");
            }
          }
          // if (newRowData.sale_price) {
          //   newRowData.price_times = numeral(newRowData.sale_price / newRowData.cost_price).format('0.00');
          // }
        }

        // 如果填写的是标签价
        if (prop === "sale_price") {
          newRowData.sale_price = numeral(newRowData.sale_price).format("0.00");
          // 如果成本价存在，算出标签价
          // if (newRowData.cost_price) {
          //   newRowData.price_times = numeral(newRowData.sale_price / newRowData.cost_price).format('0.00');
          // }
          // if (newRowData.price_times) {
          //   newRowData.cost_price = numeral(newRowData.sale_price / newRowData.price_times).format('0.00');
          // }
          newRowData.price_times = "";
        }
        this.allot_goods.splice(row - 1, 1, newRowData);
      }
    },
    handleGoods(goods) {
      this.allot_goods = [...goods, ...this.allot_goods];
      // 去重
      let newArr = [];
      let obj = {};
      this.allot_goods.forEach((item) => {
        // 如果是反确认  可操作数为1
        if (this.row.edit_status === 20) {
          item.can_doing = 1;
        }
        const { goods_id } = item;
        if (!obj[goods_id]) {
          obj[goods_id] = true;
          const newItem = { ...item };
          newArr.push(newItem);
        }
      });
      this.allot_goods = newArr;
      this.handleSum();
    },
    onSubmit() {},
    handleClose() {
      this.dialogVisible = false;
      this.infoVisibel = false;
      this.goodVisibel = false;
      this.form = {};
      this.formInfo = {};
    },
    getCellIndex({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex + 1;
      column.index = columnIndex;
    },
    hanldeCellClick(row, column) {
      const coordinate = { ...this.coordinate };
      coordinate.row = row.index;
      coordinate.column = column.index;
      this.coordinate = coordinate;
      this.$nextTick(() => {
        try {
          const dom = document.getElementById(
            "input" + row.index + '-' + column.index
          );
          dom.focus();
        } catch (error) {
          // console.log('无法聚焦');
        }
      });
    },
    goBack() {
      this.$emit("hide");
    },
    cancelTable() {
      const coordinate = { ...this.coordinate };
      coordinate.row = -1;
      coordinate.column = -1;
      this.coordinate = coordinate;
      this.tableVisiable = false;
    },
  },
};
</script>

<style lang="less" scoped>
.remark {
  width: 50%;
  padding: 20px;
  display: flex;
}
/deep/ .el-button--text {
  color: #f56c6c;
}
.form {
  background: white;
  padding: 20px 10px;
}
.total {
  display: flex;
  justify-content: space-between;
  line-height: 42px;
  padding: 0 20px;
  background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border-top: 1px solid #ddd;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  &-left {
  }
  &-right {
    display: flex;
    &-item {
      font-size: 14px;
      padding: 0 10px;
      span {
        color: #fd563a;
        font-weight: bold;
      }
    }
  }
}
.btns {
  padding: 20px;
  background: white;
  margin-top: 20px;
  position: fixed;
  left: 84px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 9;
}
.tip {
  margin-bottom: 30px;
  margin-left: 20px;
}
.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.title {
  line-height: 80px;
  font-weight: bold;
  font-size: 16px;
}
</style>
