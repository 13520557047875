<template>
  <div style="background: white; padding-top: 10px">
    <div class="btn">
      <el-button
        v-if="type === 'transferDetails' && arr[6].value === '未签收'"
        type="primary"
        plain
        @click="handleCheck"
        >核对</el-button
      >
      <el-button
        v-if="
          type === 'transferDetails' &&
          (arr[6].value === '已签收' || arr[6].value === '拒绝签收')
        "
        type="primary"
        plain
        @click="handleCheck"
        >核对详情</el-button
      >
      <el-button
        v-if="type === 'stockDetails'"
        type="primary"
        plain
        @click="handleConfigTable"
        >列表配置</el-button
      >
      <el-button type="primary" plain v-if="showPrint" @click="handlePrint"
        >打印</el-button
      >
      <el-button
        type="primary"
        plain
        @click="handleExport"
        icon="el-icon-upload2"
        :loading="exportLoading"
        >导出</el-button
      >
    </div>
    <div class="top">
      <div class="item" v-for="item in arr" :key="item.id">
        <div class="item-left">{{ item.name }}</div>
        <div class="item-right">{{ item.value }}</div>
      </div>
    </div>
    <div class="tabs" style="border: none">
      <el-tabs v-model="activeName">
        <el-tab-pane label="明细" name="first">
          <div class="table" style="overflow-x: auto">
            <el-table
              :data="tableData1.list"
              :row-class-name="handleRow"
              :span-method="objectSpanMethod"
              :border="border"
              show-summary
              :summary-method="getSummaries"
              min-height="400"
              stripe
              row-key="goods_id"
              @selection-change="handleSelectionChange"
              ref="multipleTable"
            >
              <el-table-column
                type="selection"
                align="center"
                :reserve-selection="true"
                width="60"
              >
              </el-table-column>
              <template v-for="(item, index) in rowList1">
                <el-table-column
                  v-if="item.hide ? false : true"
                  :key="index"
                  :label="item.label"
                  :prop="item.prop"
                  align="center"
                  :width="item.t_width"
                >
                  <template v-slot="scope">
                    <template v-if="item.isImg || item.prop === 'goods_pic'">
                      <el-image
                        :src="scope.row[item.prop]"
                        :preview-src-list="[scope.row[item.prop]]"
                        fit="cover"
                      >
                        <div slot="error" class="image-slot">
                          <!-- <img src="../../../assets/images/no_img.png" /> -->
                        </div>
                      </el-image>
                    </template>
                    <template v-else-if="item.prop === 'goods_name'">
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <!-- <span :class="scope.row.goods_name === tableData1.list[scope.row.index - 2].goods_name? '': 'red'">{{ scope.row.goods_name }}</span> -->
                        <span v-if="scope.row.index % 2">{{
                          scope.row[item.prop] || "--"
                        }}</span>
                        <span
                          v-else
                          :class="
                            scope.row[item.prop] ==
                            tableData1.list[scope.row.index - 2][item.prop]
                              ? ''
                              : scope.row.label === '修改'
                              ? 'red'
                              : ''
                          "
                        >
                          {{ scope.row[item.prop] || "--" }}
                        </span>
                        <template v-for="item in scope.row.goods_tag_pic">
                          <img
                            :src="item"
                            :key="item"
                            style="margin-left: 5px; height: 15px"
                          />
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      <span v-if="scope.row.index % 2">{{
                        scope.row[item.prop] || "--"
                      }}</span>
                      <span
                        v-else
                        :class="
                          scope.row[item.prop] ==
                          tableData1.list[scope.row.index - 2][item.prop]
                            ? ''
                            : scope.row.label === '修改'
                            ? 'red'
                            : ''
                        "
                      >
                        {{ scope.row[item.prop] || "--" }}
                      </span>
                    </template>
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </div>
          <div class="pagina">
            <div style="margin-right: auto">
              <span style="margin: 0 10px; color: #666"
                >已选择 {{ multipleSelection.length || 0 }} 行</span
              >
              <el-button type="text" @click="handleCopy" id="copy"
                >复制货号</el-button
              >
              <el-button type="text" style="color: #f56c6c" @click="handleClear"
                >清除</el-button
              >
            </div>
            <el-pagination
              background
              @current-change="changePage"
              @size-change="handleSizeChange"
              layout="total, sizes, prev, pager, next, jumper"
              :page-size="$store.state.pageSizes[0]"
              :page-sizes="$store.state.pageSizes"
              :total="tableData1.total"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="操作日志" name="second">
          <div class="table">
            <el-table
              :data="tableData2.list"
              :row-class-name="handleRow"
              height="450"
              stripe
            >
              <template v-for="(item, index) in rowList2">
                <el-table-column
                  :key="index"
                  :label="item.label"
                  :prop="item.prop"
                  align="center"
                  :width="item.width"
                >
                </el-table-column>
              </template>
            </el-table>
          </div>
          <div class="pagina">
            <el-pagination
              background
              @size-change="handleSizeChange2"
              @current-change="changePage2"
              layout="total, sizes, prev, pager, next, jumper"
              :page-size="$store.state.pageSizes[0]"
              :page-sizes="$store.state.pageSizes"
              :total="tableData2.total"
            >
            </el-pagination>
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </div>
    <el-dialog
      :visible.sync="tableVisiable"
      title="设置表头"
      width="30%"
      :destroy-on-close="true"
      :before-close="cancelTable"
    >
      <el-button
        type="primary"
        plain
        style="margin-bottom: 20px"
        @click="handleReset"
        >恢复默认</el-button
      >
      <span style="color: #f56c6c; margin-left: 10px"
        >*可拖拽行改变列表排序</span
      >
      <div class="table" style="border: 1px solid #ddd">
        <div style="height: 400px; overflow-y: auto">
          <ux-grid border row-key ref="plxTable" :data="rowList">
            <ux-table-column
              v-for="item in tableColumn"
              align="center"
              :key="item.id"
              :resizable="item.resizable"
              :field="item.field"
              :title="item.title"
              :sortable="item.sortable"
              :width="item.minWidth"
              :fixed="item.fixed"
              show-summary
            >
              <template v-slot="scope" v-if="item.field === 't_width'">
                <el-input v-model="scope.row.t_width"></el-input>
              </template>
            </ux-table-column>
            <ux-table-column title="是否显示" align="center" field="t_is_use">
              <template v-slot="scope">
                <el-switch
                  v-model="scope.row.t_is_use"
                  :active-value="1"
                  :inactive-value="0"
                ></el-switch>
                <!-- :disabled="
                  scope.row.field_alias === 'entry_number' ? true : false
                " -->
              </template>
            </ux-table-column>
          </ux-grid>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tableVisiable = false">取 消</el-button>
        <el-button type="primary" @click="handleTrue" :loading="tableLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Sortable from "sortablejs/modular/sortable.core.esm.js";
import { post } from "@/utils/request";
import ClipboardJS from "clipboard";
import {
  detailSet,
  detailIndex,
  detailRestore,
} from "@/api/goods/goodsHandler/stock/index.js";

export default {
  name: "Detail",
  data() {
    return {
      tableLoading: false,
      tableColumn: [
        { title: "表头字段", field: "field_text" },
        { title: "宽度(px)", field: "t_width" },
        // { title: '是否显示', field: 't_is_use' },
      ],
      multipleSelection: [],
      border: false,
      activeName: "first",
      exportLoading: false,
      tableVisiable: false,
      coordinate: {},
      rowList: [],
      originalGoodsTotal:{},//原始商品总合计数
    };
  },
  props: {
    copy: {
      type: Boolean,
      default: false,
    },
    isExport: Boolean,
    arr: Array,
    goodsTotal:Object,
    rowList1: Array,
    tableData1: Object,
    rowList2: Array,
    tableData2: Object,
    exportUrl: String, // 导出接口路径
    exportData: Object, // 导出接口请求参数
    exportName: String, // 导出文件名
    type: String,
    showPrint: Boolean,
    function_code: String, // 详情表头参数
  },
  watch: {
    goodsTotal(news,olds){
      console.log(news)

      this.originalGoodsTotal=news;
    },
    rowList1() {
      this.rowList1.forEach((item) => {
        if (item.prop === "goods_name") {
          item.t_width = 200;
        }
      });
    },
    tableVisiable: {
      handler(val) {
        if (val) {
          this.columnDrop();
        }
      },
      immediate: true,
    },
  },
  methods: {
    getSummaries(param){
      // const sums = [];
      // sums[0]="总价";
      // console.log(param)
      // console.log(this.originalGoodsTotal)
      // for(let i=0;i<this.originalGoodsTotal.length;i++){

      //   if(Number(this.originalGoodsTotal[i].value)){

      //        sums[i+1]=this.originalGoodsTotal[i].value;

      //   }
      // }

      // return sums;
      // const { columns, data } = param;
      // let originalGoodsTotal=this.originalGoodsTotal;
      //   const sums = [];
      //   //  console.log(data)
      //   columns.forEach((column, index) => {
      //     if (index === 0) {
      //       sums[index] = '合计';
      //       return;
      //     }else{
      //       // console.log(column.property)
      //       // console.log(originalGoodsTotal)
      //       if(originalGoodsTotal[column.property]){
      //         sums[index] = originalGoodsTotal[column.property];
      //       }
      //     }

      //     const values = data.map(item => Number(item[column.property]));

      //     if (!values.every(value => isNaN(value))) {
      //       sums[index] = values.reduce((prev, curr) => {
      //         const value = Number(curr);
      //         if (!isNaN(value)) {
      //           return prev + curr;
      //         } else {
      //           return prev;
      //         }
      //       }, 0);
      //       sums[index] += ' 元';
      //     } else {
      //       // sums[index] = 'N/A';
      //     }
      //   });

      //   return sums;
    },
    handleReset() {
      this.$confirm("确定要重置列表配置吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        detailRestore({ function_code: this.function_code }).then((res) => {
          if (res.code === 1) {
            this.tableVisiable = false;
            this.$message.success("重置成功");
            this.getDetailIndex();
          }
        });
      });
    },
    getDetailIndex() {
      detailIndex({ function_code: this.function_code }).then((res) => {
        if (res.code === 1) {
          res.data.map((item) => {
            item.label = item.field_text;
            item.prop = item.field_alias;
            return item;
          });
          this.rowList1 = JSON.parse(
            JSON.stringify(res.data.filter((item) => item.t_is_use === 1))
          );
          this.rowList = res.data;
        }
      });
    },
    handleTrue() {
      this.tableLoading = true;
      // 需要重新渲染表格
      this.$emit("onRowList", []);
      this.rowList.forEach((item, index) => {
        item.t_order = this.rowList.length - index;
      });
      detailSet({
        function_code: this.function_code,
        data: this.rowList,
      })
        .then((res) => {
          if (res.code === 1) {
            this.tableLoading = false;
            this.tableVisiable = false;
            this.getDetailIndex();
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    columnDrop() {
      this.$nextTick(() => {
        let plxTable = this.$refs.plxTable;
        const tbodyDom = document
          .getElementsByClassName("elx-table--body")[0]
          .getElementsByTagName("tbody")[0];

        // 关于sortable的配置https://www.cnblogs.com/xiangsj/p/6628003.html
        this.sortable = Sortable.create(tbodyDom, {
          handle: ".elx-body--row", // Drag handle selector within list items
          // 拖拽结束
          onEnd: ({ item, newIndex, oldIndex }) => {
            let currRow = this.rowList.splice(oldIndex, 1)[0];
            this.rowList.splice(newIndex, 0, currRow);
          },
        });
      });
    },
    handleConfigTable() {
      this.getDetailIndex();
      this.tableVisiable = true;
    },
    cancelTable() {
      const coordinate = { ...this.coordinate };
      coordinate.row = -1;
      coordinate.column = -1;
      this.coordinate = coordinate;
      this.tableVisiable = false;
    },
    handlePrint() {
      this.$emit("onPrint");
    },
    /*
     *  调拨核对操作
     */
    handleCheck() {
      this.$emit("transferCheck");
    },
    handleClear() {
      const clipboard = new ClipboardJS("#copy", {
        text() {
          return "";
        },
      });
      this.$refs.multipleTable.clearSelection();
    },
    handleCopy() {
      if (!this.multipleSelection.length) {
        this.$message.warning("请勾选");
        return;
      }
      if (!this.multipleSelection.length > 1000) {
        this.$message.warning("最多勾选1000条");
        return;
      }
      let str = "";
      this.multipleSelection.forEach((item) => {
        str += `${item.goods_number}\n`;
      });
      const clipboard = new ClipboardJS("#copy", {
        text() {
          return str.slice(0, str.length - 1);
        },
      });
      this.$message.success("复制成功");
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    /*
     *  导出操作
     */
    handleExport() {
      this.exportLoading = true;
      const exportExcel = () =>
        post({
          url: this.exportUrl,
          data: this.exportData,
        });
      exportExcel()
        .then((res) => {
          this.exportLoading = false;
          var filename = this.exportName;
          res = "\ufeff" + res;
          var blob = new Blob([res], { type: "application/vnd.ms-excel" });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            var urlCreator = window.URL || window.wibkitURL;
            var url = urlCreator.createObjectURL(blob);
            var a = document.createElement("a");
            a.download = filename;
            a.href = url;
            a.click();
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    handleSizeChange(page_num) {
      this.$emit("onSizeChange", page_num);
    },
    handleSizeChange2(page_num) {
      this.$emit("onSizeChange2", page_num);
    },
    handleRow({ row, rowIndex }) {
      row.index = rowIndex + 1;
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      for (let i = 0; i < this.rowList1.length; i++) {
        const item = this.rowList1[i];
        if (item.span) {
          this.border = true;
          if (columnIndex === i) {
            if (rowIndex % item.span === 0) {
              return {
                rowspan: item.span,
                colspan: 1,
              };
            } else {
              return {
                rowspan: 0,
                colspan: 0,
              };
            }
          }
        }
      }
    },
    changePage(page) {
      this.$emit("onChangePage1", page);
    },
    changePage2(page) {
      this.$emit("onChangePage2", page);
    },
  },
};
</script>

<style lang="less" scoped>
.btn {
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px 0px 0px;
}
/deep/ .el-tabs__nav-wrap::after {
  height: 1px;
}
/deep/ .el-tabs__content {
  background: white;
}
/deep/ .red {
  color: #fd563a;
}

/deep/ .el-image {
  margin-top: 3px;
  width: 58px;
  height: 58px;
}
/deep/ .image-slot {
  width: 54px;
  height: 54px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
/deep/ .el-tabs__header {
  margin: 0 !important;
  padding-left: 50px;
}
/deep/ .tabs {
  padding: 0 !important;
}
/deep/ .el-tabs__item {
  font-size: 16px;
  height: 60px;
  line-height: 60px;
}
.top {
  display: flex;
  padding: 0px 50px 10px 50px;
  background: white;
  border-radius: 6px;
  flex-wrap: wrap;
  font-size: 14px;
  margin-top: 20px;
  .item {
    display: flex;
    margin-bottom: 20px;
    width: 25%;
    &-left {
      width: 100px;
      color: #909399;
      font-size: 16px;
      position: relative;
      &::before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #2791ff;
        position: absolute;
        top: 10px;
        left: -15px;
      }
    }
    &-right {
      color: #565656;
      font-size: 16px;
    }
  }
}
.tabs {
  background: white;
  margin-top: 20px;
  padding: 0 20px;
}
</style>
