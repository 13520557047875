<template>
  <div>
    <div class="card">
      <el-page-header
        @back="goBack" content="截金单详情"
      >
      </el-page-header>
    </div>
    <Detail
      @onPrint="handlePrint"
      :showPrint="showPrint"
      :arr="arr"
      :rowList1="rowList1"
      :tableData1="tableData1"
      :rowList2="rowList2"
      :tableData2="tableData2"
      @onChangePage1="handlePage"
      @onChangePage2="handlePage2"
      @onSizeChange="handleSizeChange"
      @onSizeChange2="handleSizeChange2"
      :exportUrl="'/store/goods.CutMetarialCtl/detailExport'"
      :exportData="{
        cut_metarial_id: this.propObj.value
      }"
      exportName="截金单详情导出文件"
    />
    <PrintConf function_code="cutGoodsPrint" @onRowList="handlePrintRow" v-show="false" />
    <LocalPrint :show="false" :printRows="printRows" :printData="printData" :title="title" :dialogVisible="localPrintVisible" @onClose="localPrintVisible = false" />
   </div>
</template>

<script>
import storage from 'good-storage';
import Detail from './components/detail';
import { getAttrList } from '@/api/goods/goodsWarehousing/warehousing';
// import { detailReq, detailGoodsListReq, getLogListReq } from '@/api/goods/goodsWarehousing/modifyList/detail';
import { getEditGoodsPrintReq } from '@/api/goods/goodsWarehousing/modifyList/list';
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";
import numeral from 'numeral';
import {orderDetail,detailGoodsList,printOrder,detailExport} from '@/api/order/offlineOrder/cut'
export default {
  data() {
    return {
      localPrintVisible: false,
      printData: {},
      printRows: [],
      loading: false,
      page: 1,
      page_num: this.$store.state.pageSizes[0],
      page2: 1,
      page_num2: this.$store.state.pageSizes[0],
      userInfo: {},
      arr: [
        {
          id: 0,
          name: '截金单号',
          value: '',
          prop: 'cut_number',
        },
        {
          id: 1,
          name: '修改数量',
          value: '',
          prop: 'total_num',
        },
        {
          id: 2,
          name: '审核状态',
          value: '',
          prop: 'edit_status_text',
        },
        {
          id: 3,
          name: '创建人',
          value: '',
          prop: 'create_store_user_name',
        },
        {
          id: 4,
          name: '创建时间',
          value: '',
          prop: 'create_time',
        },
        {
          id: 5,
          name: '审核人员',
          value: '',
          prop: 'audit_store_user_name',
        },
        {
          id: 6,
          name: '审核时间',
          value: '',
          prop: 'audit_time_text',
        },
        {
          id: 7,
          name: '所属品类',
          value: '',
          prop: 'goods_type_name',
        },
        {
          id: 8,
          name: '备注',
          value: '',
          prop: 'remark',
        },
        {
          id: 9,
          name: '未通过原因',
          value: '',
          prop: 'audit_remark',
        },
      ],
      rowList1: [],
      tableData1: {
        list: [],
        total: 0,
      },
      rowList2: [
        {
          label: '操作时间',
          prop: 'create_time',
          width: 200,
        },
        {
          label: '操作记录',
          prop: 'msg',
        },
        {
          label: '操作人',
          prop: 'create_user_name',
          width: 200,
        },
      ],
      tableData2: {
        list: [],
        total: 0,
      },
    };
  },
  components: {
    Detail,
    PrintConf,
    LocalPrint,
  },
  props: {
    title: String,
    showPrint: Boolean,
    // allot_id: Number,
    propObj: {
      type: Object,
      default: () => (
        {
          key: 'cut_metarial_id',
          value: 0,
        }
      ),
    },
    goods_type_id: {
      type: Number,
      default: 36,
    },
  },
  created() {
    const userInfo = storage.get('userInfo');
    this.userInfo = userInfo;
    this.getDetail();
    this.handleArrList();
    this.getDetailGoods();
    // this.getLogList();
  },
  methods: {
    getEditGoodsPrint(cond) {
      printOrder({ ...cond })
        .then((res) => {
          if (res.code === 1) {
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            res.data.top = [];
            const data = res.data.data;
            const arr1 = [
              { txt: '创建人', value: data.create_store_user_name, width: '24%' },
              { txt: '创建时间', value: data.create_time, width: '24%' },
              { txt: '截金单号', value: data.cut_number, width: '30%' },
            ];
            res.data.bottom = [];
            const arr2 = [
              { txt: '审核人', value: data.audit_store_user_name, width: '24%' },
              { txt: '审核时间', value: data.audit_time_text ? data.audit_time_text : '', width: '30%' },
            ];
            const arr3 = [
              {
                txt: '门店', value: data.hierarchy_name, width: '24%',
              },
            ];
            res.data.top.push(arr1, arr3);
            res.data.bottom.push(arr2);
            // 计算合计
            this.printData = res.data;
            const sum = {
              index: '合计',
              info: [
                { edit_sign: '原始' },
                { edit_sign: '修改' },
                { edit_sign: '差异' },
              ],
            };
            const decimal = {};
            Object.keys(res.data.list[0].goods_info.old_info).forEach((key) => {
              if (!decimal[key]) {
                const arr = String(res.data.list[0].goods_info.old_info[key]).split(".");
                if (arr.length === 2) {
                  decimal[key] = arr[1].length;
                } else {
                  decimal[key] = 0;
                }
              }
            });
            this.printData.list.forEach((item) => {
              if (item.goods_info) {
                if (!item.info) {
                  item.info = [];
                }
                item.info.push({ ...item.goods_info.old_info, edit_sign: '原始' });
                item.info.push({ ...item.goods_info.new_info, edit_sign: '修改' });
              }
              const diff = { edit_sign: '差异' };
              this.printRows.forEach((row) => {
                // 差异值行
                if (row.is_sum && row.is_use) {
                  if (!item.goods_info.new_info[row.field_alias]) {
                    item.goods_info.new_info[row.field_alias] = 0;
                  }
                  if (!item.goods_info.old_info[row.field_alias]) {
                    item.goods_info.old_info[row.field_alias] = 0;
                  }
                  diff[row.field_alias] = Number(item.goods_info.new_info[row.field_alias]) - Number(item.goods_info.old_info[row.field_alias]);
                  // const arr = String(item.goods_info.old_info[row.field_alias]).split(".");
                  // if (arr.length === 2) {
                  //   diff[row.field_alias] = diff[row.field_alias].toFixed(arr[1].length);
                  // }
                  if (diff[row.field_alias] % 1 !== 0 && diff[row.field_alias]) {
                    diff[row.field_alias] = Number(numeral(diff[row.field_alias]).format('0.0000')) + 0;
                  }

                  // 合计
                  item.info.forEach((infoData, infoIndex) => {
                    if (!sum.info[infoIndex][row.field_alias]) {
                      sum.info[infoIndex][row.field_alias] = 0;
                    }
                    sum.info[infoIndex][row.field_alias] += Number(infoData[row.field_alias]);
                  });
                }
              });
              item.info.push(diff);
            });
            // 需要保留多少位小数 以及 总差异
            Object.keys(sum.info[0]).forEach((key) => {
              if (key !== 'edit_sign') {
                sum.info[2][key] = Number(sum.info[1][key]) - Number(sum.info[0][key]);
                if (sum.info[0][key] % 1 !== 0 && sum.info[0][key]) {
                  sum.info[0][key] = Number(numeral(sum.info[0][key]).format('0.0000')) + 0;
                }
                if (sum.info[1][key] % 1 !== 0 && sum.info[1][key]) {
                  sum.info[1][key] = Number(numeral(sum.info[1][key]).format('0.0000')) + 0;
                }
                if (sum.info[2][key] % 1 !== 0 && sum.info[2][key]) {
                  sum.info[2][key] = Number(numeral(sum.info[2][key]).format('0.0000')) + 0;
                }
              }
            });
            this.printData.list.push(sum);
            this.localPrintVisible = true;
          }
        })
    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
    handlePrint() {
      this.getEditGoodsPrint({
        cut_metarial_id: this.propObj.value,
      });
    },
    // 操作日志
    getLogList() {
      getLogListReq({
        [this.propObj.key]: this.propObj.value,
        page: this.page2,
        limit: this.page_num2,
      })
        .then((res) => {
          if (res.code === 1) {
            this.tableData2 = res.data;
          }
        });
    },
    // 获取列表的列
    handleArrList() {
      getAttrList({ goods_type_id: this.goods_type_id, isEdit: 1 })
        .then((res) => {
          if (res.code === 1) {
            const newArr = [];
            newArr.push({
              label: '序号',
              prop: 'tabIndex',
              span: 2,
            });
            res.data.forEach((item) => {
              newArr.push({
                label: item.attr_name,
                prop: '' + item.attr_fields,
                hide: item.hide,
                // span: item.attr_fields === 'goods_number' ? 2 : 0,
              });
            });
            newArr.splice(1, 0, {
              label: '修改标志',
              prop: 'label',
            });
            this.rowList1 = newArr;
          }
        });
    },
    getDetail() {
      console.log(this.propObj)
      orderDetail({
        edit_id:this.propObj.value
      })
        .then((res) => {
          if (res.code === 1) {
            if (res.data.allot_status === 40 || res.data.allot_status === 50) {
              this.arr = [...this.arr2];
            }
            this.arr.forEach((item) => {
              item.value = (res.data[item.prop] ? res.data[item.prop] : '--');
            });
          }
        });
    },
    getDetailGoods() {
      detailGoodsList({ [this.propObj.key]: this.propObj.value, page: this.page, page_num: this.page_num, isEdit: 1 })
        .then((res) => {
          if (res.code === 1) {
            const newArr = [];
            res.data.list.forEach((item, index) => {
              if (item.goods_info) {
                newArr.push({ ...item.goods_info.old_info, tabIndex: index + 1, label: '原始', edit_id: item.cut_metarial_id, goods_id: item.goods_id, goods_info: item.goods_info, goods_tag_pic: item.goods_tag_pic });
                newArr.push({ ...item.goods_info.new_info, tabIndex: index + 1, label: '修改', edit_id: item.cut_metarial_id, goods_id: item.goods_id, goods_info: item.goods_info, goods_tag_pic: item.goods_tag_pic });
              }
            });
            this.tableData1 = {
              list: newArr,
              total: res.data.total,
            };
          }
        });
    },
    goBack() {
      this.$emit('hide');
    },
    handleSizeChange(page_num) {
      this.page = 1;
      this.page_num = page_num;
      this.getDetailGoods();
    },
    handleSizeChange2(page_num) {
      this.page2 = 1;
      this.page_num2 = page_num;
      // this.getLogList();
    },
    handlePage(page) {
      this.page = page;
      this.getDetailGoods();
    },
    handlePage2(page) {
      this.page2 = page;
      // this.getLogList();
    },
  },
};
</script>
