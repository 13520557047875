import { post } from '@/utils/request';
import { P } from '@antv/g2plot';


//截金单列表
const cutList=data=>post({
    url:'/store/goods.CutMetarialCtl/list',
    data,
})

//商品属性
const getAttrList=data=>post({
    url:'/store/goods.AttrCtl/getAttrList',
    data
})

//筛选货品列表
const getGoodsList=data=>post({
    url:'/store/goods.Goods/getGoodsList',
    data,
})

//添加截金单
const createOrder=data=>post({
    url:'/store/goods.CutMetarialCtl/create',
    data,
})

//截金单详情
const orderDetail=data=>post({
    url:'/store/goods.CutMetarialCtl/detail',
    data
})

//截金单货品列表
const detailGoodsList=data=>post({
    url:'/store/goods.CutMetarialCtl/detailGoodsList',
    data,
})

//审核截金单
const cutMetarial=data=>post({
    url:'/store/goods.CutMetarialCtl/audit',
    data,
})

//编辑截金单
const editOrder=data=>post({
    url:'/store/goods.CutMetarialCtl/create',
    data,
})

// 打印截金单
const printOrder=data=>post({
    url:'/store/goods.CutMetarialCtl/cutGoodsPrint',
    data,
})

//解锁
const getIsLock=data=>post({
    url:'/store/shop.Settlement/getIsLock',
    data,
})

//详情导出
const detailExport=data=>post({
    url:'/store/goods.CutMetarialCtl/detailExport',
    data,
    responseType: 'blob',
})

//列表导出
const listExport=data=>post({
    url:'/store/goods.CutMetarialCtl/listExport',
    data,
    responseType:'bolb'
})

//审核不通过删除
const deleteNone=data=>post({
    url:'/store/goods.CutMetarialCtl/delete',
    data,
})

//审核通过删除
const resetDelete=data=>post({
    url:'/store/goods.CutMetarialCtl/resetDelete',
    data,
})

//反确认详情
const resetDetailGoodsList=data=>post({
    url:'/store/goods.CutMetarialCtl/resetDetailGoodsList',
    data,
})

//保存并锁定
const resetSave=data=>post({
    url:'/store/goods.CutMetarialCtl/reset',
    data,
})

export {
    resetSave,
    resetDetailGoodsList,
    resetDelete,
    deleteNone,
    listExport,
    detailExport,
    getIsLock,
    printOrder,
    editOrder,
    cutMetarial,
    detailGoodsList,
    orderDetail,
    createOrder,
    getGoodsList,
    getAttrList,
    cutList,
}