var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('el-page-header',{attrs:{"content":"截金单审核"},on:{"back":_vm.goBack}})],1),_c('div',{staticClass:"table"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.tableData.list,"row-class-name":_vm.handleRow,"span-method":_vm.objectSpanMethod,"border":""}},[_vm._l((_vm.rowList),function(item,index){return [(item.hide ? false : true)?_c('el-table-column',{key:index,attrs:{"label":item.label,"prop":item.prop,"align":"center","width":item.prop === 'goods_name' ? 200 : ''},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(item.isImg)?[_c('el-image',{attrs:{"src":scope.row[item.prop],"preview-src-list":[scope.row[item.prop]]}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('img',{attrs:{"src":require("../../../../assets/images/no_img.png")}})])])]:(item.prop === 'goods_name')?[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[(scope.row.index % 2)?_c('span',[_vm._v(_vm._s(scope.row[item.prop] || '--'))]):_c('span',{class:scope.row[item.prop] ===
                      _vm.tableData.list[scope.row.index - 2][item.prop]
                        ? ''
                        : scope.row.label === '修改'
                        ? 'red'
                        : ''},[_vm._v(" "+_vm._s(scope.row[item.prop] || '--')+" ")]),_vm._l((scope.row.goods_tag_pic),function(item){return [_c('img',{key:item,staticStyle:{"margin-left":"5px","height":"15px"},attrs:{"src":item}})]})],2)]:[(scope.row.index % 2)?_c('span',[_vm._v(_vm._s(scope.row[item.prop] || '--'))]):_c('span',{class:scope.row[item.prop] ===
                  _vm.tableData.list[scope.row.index - 2][item.prop]
                    ? ''
                    : scope.row.label === '修改'
                    ? 'red'
                    : ''},[_vm._v(" "+_vm._s(scope.row[item.prop] || '--')+" ")])]]}}],null,true)}):_vm._e()]})],2)],1),_c('div',{staticClass:"pagina"},[_c('el-pagination',{attrs:{"current-page":_vm.page,"background":"","layout":"total, sizes, prev, pager, next, jumper","total":_vm.tableData.total,"page-size":_vm.$store.state.pageSizes[0],"page-sizes":_vm.$store.state.pageSizes},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('div',{staticStyle:{"padding":"20px","display":"flex","margin-bottom":"80px"}},[_c('div',{staticStyle:{"width":"50px"}},[_vm._v("备注：")]),_c('div',{staticStyle:{"flex":"1"}},[_vm._v(_vm._s(this.tableData.remark || '--'))])]),_c('el-dialog',{attrs:{"title":"请输入原因","visible":_vm.reasonVisible,"width":"30%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.reasonVisible=$event}}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 4, maxRows: 4 },"placeholder":"请输入原因"},model:{value:(_vm.audit_remark),callback:function ($$v) {_vm.audit_remark=$$v},expression:"audit_remark"}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleReason}},[_vm._v("确 定")])],1)],1),_c('div',{staticClass:"btns"},[_c('el-button',{staticStyle:{"margin-right":"20px"},attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.toPass}},[_vm._v(" 审核通过 ")]),_c('el-button',{attrs:{"type":"primary","plain":"","loading":_vm.loading},on:{"click":_vm.toFail}},[_vm._v("审核不通过")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }